
import {InputText} from 'primereact/inputtext';
import { Password } from 'primereact/password'; 
import { Button } from 'primereact/button';
import { useCallback, useState } from 'react';
import { useAuthUser } from './hooks/useAuthUser';
const LoginPage = () => {
	const { login } = useAuthUser();
	const [user,setUser] = useState('');
	const [password, setPassword] = useState('');
	const handleLogin = () => {
		login(user,password);
	};

	const onKeyPressHandler= useCallback(event=>{
		event.preventDefault();
		if (event.key === 'Enter') {
			login(user, password);
		}
	},[login, password, user]);

	return (
		<div
			className='align-items-center flex justify-content-center lg:px-8 md:px-6 px-4 py-8  ng-star-inserted h-screen'
			style={{
				backgroundColor: 'darkblue',
				backgroundImage: '-webkit-linear-gradient(top left, darkblue, lightgray)',
			}}
		>
			<div className='p-4 shadow-4 border-round w-full lg:w-6 bg-gray-200'>
				<div className='text-center mb-5'>
					
					<div className='text-900 text-3xl font-medium mb-3'>Bienvenid@</div>
				</div>
				<div>
					<label className='block text-900 font-medium mb-2'>Datos de entrada</label>
					<InputText
						id='email1'
						type='text'
						placeholder='Usuario'
						className='w-full '
						value={user}
						onChange={(e) => setUser(e.target.value)}
						onKeyUp={onKeyPressHandler}
					/>
					<label className='block text-900 font-medium mb-2 pt-3'></label>
					<Password
						id='password1'
						type='password'
						value={password}
						placeholder='Contraseña'
						feedback={false}
						style={{ paddingBottom: '2%', width: '100%', minWidth: '100%' }}
						onChange={(e) => setPassword(e.target.value)}
						onKeyUp={onKeyPressHandler}
					/>

					<Button label='Entrar' icon='pi pi-caret-right' size='medium' severity='danger' onClick={handleLogin} />
				</div>
			</div>
		</div>
	);
};

export default LoginPage;
