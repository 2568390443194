import { useCallback, useState } from "react";
import ResultadoAnalisis from "./ResultadoAnalisis";
import useAnalisisHook from "../hooks/useAnalisisHook";
import { ProgressSpinner } from 'primereact/progressspinner';
import { useMemo } from "react";
import PreviewFactura from "./PreviewFactura";

const AnalizadorFacturas = ()=>{
   const [file, setFile] = useState(null);
   const [file64, setFile64] =useState(null);

   const formData = useMemo(()=>{
	 const formData = new FormData();
		formData.append('file', file);
		return formData;
   },[file]);

   const { resultado, isLoadingResultado } = useAnalisisHook({ fileName: file?.name, file: formData });
	const handleFileChange =useCallback((e) => {
		if (e.target.files) {
			setFile(e.target.files[0]);
			var reader = new FileReader();
			var file = e.target.files[0];

			reader.onload = function (upload) {
				 const binaryStr = upload.target.result;
				setFile64(binaryStr);
			};
			reader.readAsDataURL(file);
		}
	},[]);

	return (
		<div className='surface-ground '>
			<div className='grid'>
				<div className='col-12'>
					<div className='surface-card shadow-2 p-3 border-round'>
						<div className='flex justify-content-between mb-3'>
							<div>
								<div className='text-900 font-medium text-xl'>
									<label htmlFor='file' className='sr-only'>
										Choose a file
									</label>
									<input id='file' type='file' onChange={handleFileChange} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-12'>
					<div className='surface-card shadow-2 p-3 border-round'>
						<div className='grid'>
							<div className='col-5'>
								<PreviewFactura fichero={file64} nombre={file?.name}/>
							</div>
							<div className='col-7'>
								{isLoadingResultado && <ProgressSpinner />}
								{resultado && <ResultadoAnalisis datos={resultado} fichero={file}/>}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AnalizadorFacturas;