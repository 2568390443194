import RequireAuth from './components/auth/RequireAuth';
import LoginPage from './LoginPage';
import { Route, Routes } from 'react-router';
import { useAuthUser } from './hooks/useAuthUser';
import AnalizadorFacturas from './components/AnalizadorFacturas';

const Main = () => {
	useAuthUser();

	return (
		<Routes>
			<Route exact path='/login' element={<LoginPage />} />
			<Route
				exact
				path='/'
				element={
					<RequireAuth>
						<AnalizadorFacturas />
					</RequireAuth>
				}
			/>
			
		</Routes>
	);
};

export default Main;
