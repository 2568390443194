
const PreviewFactura = ({fichero,nombre})=>{
    console.log('FICHEROOOOOOOOO', nombre);
    if (!fichero){
        return <div>Sin fichero</div>;
    }
    return (
			<div className='h-screen'>
				{nombre?.includes('pdf') && (
					<embed
						src={fichero}
						type='application/pdf'
						height='100%'
						width='100%'
					></embed>
				)}
				{!nombre?.includes('pdf') && <img src={fichero} height='100%' width='100%' />}
			</div>
		);
};

export default PreviewFactura;
