import { Navigate } from 'react-router';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import AppContext from '../../contexts/AppContext';

const RequireAuth = ({ children,roles }) => {
	const { user } = useContext(AppContext);

	

	const isValidUser = useMemo(() => {
		if (user?.user === 'BATMAN') {
			return true;
		}
		return false;
	}, [user]);

	const intervalRef = useRef();

	const getToken = useCallback(() =>{
		
	} , []);

	useEffect(() => {
		if (isValidUser){
			const interval = setInterval(() => getToken(), 3*60*1000);
			intervalRef.current = interval;
			return () => clearInterval(interval);
		}
	}, [getToken, isValidUser]);

	if (!isValidUser) {
		return <Navigate to='/login' replace />;
	}
	if (user?.rol===3){
		return <div> NO TIENE PERMISO</div>;
	}
	
	return user ? children : <Navigate to='/login' replace />;
};

RequireAuth.propTypes = {
	children: PropTypes.any,
	roles: PropTypes.array
};
export default RequireAuth;
