import { PrimeReactProvider } from 'primereact/api';
//core
import 'primereact/resources/primereact.min.css';
//theme
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import 'react-toastify/dist/ReactToastify.css';
  import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppContext from './contexts/AppContext';
import { useEffect, useState } from 'react';
import { useLocalStorage } from './hooks/useLocalStorage';
import { ProgressSpinner } from 'primereact/progressspinner';
import Main from './Main';
import { BrowserRouter } from 'react-router-dom';

function App() {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);
	const { getItem } = useLocalStorage();

	const queryClient = new QueryClient({
		refetchOnWindowFocus: false,
		refetchOnmount: false,
		refetchOnReconnect: false,
		retry: false,
		defaultOptions: {
			queries: {
				staleTime: Infinity,
				cacheTime: 30 * 60 * 1000,
			},
		},
	});

	useEffect(() => {
		const savedU = getItem('user');
		if (savedU && JSON.parse(savedU) !== user) {
			setUser(JSON.parse(savedU));
		}
		setLoading(null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); 
  
	return (
		<PrimeReactProvider>
			<QueryClientProvider client={queryClient}>
				<AppContext.Provider value={{ user, setUser }}>
					<BrowserRouter>{!loading && <Main />}</BrowserRouter>
					
					{loading && <ProgressSpinner />}
				</AppContext.Provider>
				<ToastContainer
					position='top-right'
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme='light'
				/>

				<ToastContainer autoClose={2000} />
				<ReactQueryDevtools initialIsOpen />
			</QueryClientProvider>
		</PrimeReactProvider>
	);
}

export default App;
