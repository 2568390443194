import { getAnalisisService } from "../services/analisisService";
import { useQuery } from '@tanstack/react-query';

const useAnalisisHook = ({fileName,file})=>{

    const { data: resultado, isLoading: isLoadingResultado } = useQuery({
			queryKey: ['analisis', fileName],
			queryFn: async () => await getAnalisisService(file),
			enabled: !!fileName
		});

    return {resultado, isLoadingResultado};
}

export default useAnalisisHook;